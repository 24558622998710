<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.622"
    height="34.184"
    viewBox="0 0 34.622 34.184"
  >
    <g transform="translate(-172.449 -32.45)">
      <path
        d="M172.449,49.546A17.352,17.352,0,0,1,201.571,37.06a16.142,16.142,0,0,1,5.371,10.411,17.142,17.142,0,0,1-14.772,19,16.992,16.992,0,0,1-14.4-4.631A16.542,16.542,0,0,1,172.6,51.644C172.51,50.95,172.5,50.245,172.449,49.546Zm1.768.908a14.569,14.569,0,0,0,4.51,9.91,15.683,15.683,0,0,0,22.107-.023,14.753,14.753,0,0,0,4.421-12.267,14.522,14.522,0,0,0-4.484-9.385,15.292,15.292,0,0,0-13.921-4.232C179.591,35.724,174.217,42.171,174.217,50.453Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
      <g transform="translate(180.505 37.607)">
        <path
          d="M327.594,115.237H324.91c-.043-.309-.076-.616-.129-.919a6.47,6.47,0,0,0-.957-2.514,4.39,4.39,0,0,0-4.295-2,4.5,4.5,0,0,0-4.058,3.483c-.186.625-.273,1.279-.41,1.945h-2.647c.029-.412.04-.819.088-1.222a8.518,8.518,0,0,1,1.321-3.781A6.672,6.672,0,0,1,318.7,107.2a9.109,9.109,0,0,1,4.483.463,6.317,6.317,0,0,1,3.811,3.924,10.828,10.828,0,0,1,.612,3.53A1.094,1.094,0,0,1,327.594,115.237Z"
          transform="translate(-310.802 -107.105)"
          fill="currentColor"
          fill-rule="evenodd"
        />
        <path
          d="M307.5,242.29a3.776,3.776,0,0,0-.084-.734.738.738,0,0,0-.6-.615,3.61,3.61,0,0,0-.733-.09q-7.772-.007-15.543,0a3.317,3.317,0,0,0-.589.058.933.933,0,0,0-.814.8c-.018.1-.043.191-.065.286V252.21c.029.122.056.244.087.366a.81.81,0,0,0,.65.649,3.446,3.446,0,0,0,.691.083q7.8.007,15.607,0a3.591,3.591,0,0,0,.733-.088.709.709,0,0,0,.579-.6,4.151,4.151,0,0,0,.079-.734Q307.513,247.086,307.5,242.29Zm-7.448,8.073H296.43l1.205-3.013a1.834,1.834,0,0,1-1.3-1.851,1.905,1.905,0,0,1,1.9-1.98,1.984,1.984,0,0,1,.635,3.842Z"
          transform="translate(-289.079 -231.611)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
